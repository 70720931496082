<template>
    <div>
      <transition name="vload">
        <div v-show="isLoading" class="Loader">
          <div class="sk-cube-grid">
            <div class="sk-cube sk-cube1"></div>
            <div class="sk-cube sk-cube2"></div>
            <div class="sk-cube sk-cube3"></div>
            <div class="sk-cube sk-cube4"></div>
            <div class="sk-cube sk-cube5"></div>
            <div class="sk-cube sk-cube6"></div>
            <div class="sk-cube sk-cube7"></div>
            <div class="sk-cube sk-cube8"></div>
            <div class="sk-cube sk-cube9"></div>
          </div>
          <!--<i class="fa fa-spinner fa-spin fa-5x fa-fw" style="color:green"></i>-->
        </div>
      </transition>
        <!-- ============================================================== -->
        <!-- Main wrapper - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <div id="main-wrapper">
            <Header></Header>
            <section class="fixed auth-frame">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-sm-12 mx-auto">
                          <div class="form-title"><h1>ログイン</h1></div>
                          <div v-if="hasValidationError" class="error-area">
                              <p v-for="item in allValidationError" :key="item.validationText">
                                  ・{{item.validationText}}
                              </p>
                          </div>
                            <form @submit.prevent="send">
                                <div class="form-group">
                                    <label>メールアドレス</label>
                                    <input type="email" class="form-control" placeholder="sesmedia@yurulica.com" v-model="mailAddress">
                                </div>
                              <div class="form-group">
                                    <label>パスワード</label>
                                    <input type="password" class="form-control" placeholder="8~50桁の記号英数字で入力" v-model="password">
                                </div>
                                <button type="submit" class="btn-md full-width pop-login form-button" :disabled="mailAddress.length === 0">送信</button>
                            </form>
                          <p class="login-remarks">パスワードを忘れた方は<router-link class="link-margin" to="/requestreissue">こちら</router-link></p>
                          <p class="login-remarks">新規会員登録は<router-link class="link-margin" to="/register">こちら</router-link></p>
                        </div>

                    </div>

                </div>
            </section>
            <Footer></Footer>
        </div>
    </div>
</template>

<script>
    import Header from "@/views/components/Header";
    import Footer from "@/views/components/Footer";
    import MixIn from "@/common/mixin";
    import { decode } from 'js-base64';
    import ApiMixIn from "../../common/api_mixin";
    export default {
        name: "Login",
        components: {Footer, Header},
        mixins: [MixIn, ApiMixIn],

        data: function() {
            return {
                mailAddress: "",
                password: ""
            }
        },
        methods: {
            async send() {
                const res = await this.postApi("auth/login", { mailAddress: this.mailAddress, password: this.password }, true);
                if (res) {
                    const redirectPath = this.$route.query.redirectPath;
                    if (!redirectPath) {
                        this.$router.push({ path: '/' });
                    } else {
                        this.$router.push({ path: decode(redirectPath) });
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
